import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide will show you how to upgrade from the now-deprecated
Primer::Truncate component to the latest `}<a parentName="p" {...{
        "href": "/components/truncate/rails"
      }}><inlineCode parentName="a">{`Primer::Beta::Truncate`}</inlineCode></a>{`
component.`}</p>
    <p>{`The new `}<inlineCode parentName="p">{`Truncate`}</inlineCode>{` component additionally now includes a text slot used for the
truncated text.`}</p>
    <h2>{`Some Migration Examples`}</h2>
    <p>{`Migrating the most common use cases of the `}<inlineCode parentName="p">{`Truncate`}</inlineCode>{` component simply requires
changing the name.`}</p>
    <p>{`Previously we might have something like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Truncate.new(tag: :p)) { "Some really, really verbose content" } %>
`}</code></pre>
    <p>{`That's now:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Beta::Truncate.new(tag: :p)) { "Some really, really verbose content" } %>
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`tag`}</inlineCode>{` argument now defaults to `}<inlineCode parentName="p">{`:span`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`:div`}</inlineCode>{`, so if we want to
continue wrapping content in `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` tags we'll need to explicitly set that. So,
if we'd been using:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Truncate.new) { "Some more very very long text" } %>
`}</code></pre>
    <p>{`We can now equivalently write:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Beta::Truncate.new(tag: :div)) { "Some more very very long text" } %>
`}</code></pre>
    <h2>{`Arguments`}</h2>
    <p>{`The following arguments for the component initializer have changed between the deprecated and newer versions
of the `}<inlineCode parentName="p">{`Truncate`}</inlineCode>{` component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`From `}<inlineCode parentName="th">{`Primer::Truncate`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`To `}<inlineCode parentName="th">{`Primer::Beta::Truncate`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Previously defaulted to `}<inlineCode parentName="td">{`:div`}</inlineCode>{`, now `}<inlineCode parentName="td">{`:span`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Removed in `}<inlineCode parentName="td">{`Primer::Beta::Truncate`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`priority`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the text will be given priority (by increasing `}<inlineCode parentName="td">{`flex-basis`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The remaining arguments for `}<inlineCode parentName="p">{`Truncate`}</inlineCode>{` can be found in the documentation for
that component.`}</p>
    <p>{`Please see the following for complete descriptions and examples.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/truncate/rails"
        }}><inlineCode parentName="a">{`Primer::Beta::Truncate`}</inlineCode>{` component`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/view-components/lookbook/inspect/primer/beta/truncate/default"
        }}><inlineCode parentName="a">{`Primer::Beta::Truncate`}</inlineCode>{` Lookbook examples`}</a></li>
    </ul>
    <p>&nbsp;</p>
    <p><a parentName="p" {...{
        "href": "/guides/development/rails#upgrading-deprecated-components"
      }}>{`←`}{` Back to development docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      